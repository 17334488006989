@use '@angular/material' as mat;
@import 'vars.scss';
@import 'bootstrap/scss/bootstrap';


/* -------------------------------------------------------------------------- */
/*                          FONTS                                             */
/* -------------------------------------------------------------------------- */


/*Standardschrift für Überschriften*/

@font-face {
  font-family: "Rokkitt Regular";
  src: url("assets/fonts/Rokkitt-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Rokkitt Regular";
  src: url("assets/fonts/Rokkitt-SemiBold.ttf") format("truetype");
  font-display: swap;
  font-weight: 600;
}

/*Standardschrift für Fliesstext*/

@font-face {
  font-family: "Lato Regular";
  src: url("assets/fonts/Lato-Regular.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Lato Regular";
  src: url("assets/fonts/Lato-Bold.ttf") format("truetype");
  font-display: swap;
  font-weight: bold;
}

/* -------------------------------------------------------------------------- */
/*                          GLOBAL CLASSES SETUP                              */
/* -------------------------------------------------------------------------- */
* {
  font-family: $finport-font-family-sans-serif;
}

h1 {
  font-family: $finport-font-family-serif;
  color: $finport-primary-colour-500;
}

h2 {
  font-family: $finport-font-family-serif;
  font-size: 1.5rem !important;
  color: $finport-primary-colour-500;
}

h3 {
  font-family: $finport-font-family-serif;
  font-size: 1.2rem !important;
  color: $finport-primary-colour-500;
}

h4 {
  font-family: $finport-font-family-sans-serif;
  font-size: 1.1rem !important;
  color: $finport-primary-colour-500;
}

h5 {
  font-family: $finport-font-family-sans-serif;
  font-size: 1rem !important;
  color: $finport-primary-colour-500;
}

p {
  font-family: $finport-font-family-sans-serif;
  color: $finport-colour-black;
}

span {
  font-family: $finport-font-family-serif;
  font-size: 1rem;
}

button {
  border-radius: 10px !important;
}

a {
  text-decoration: none;
}

ul, ol {
  padding-inline-start: 30px;
}


/* -------------------------------------------------------------------------- */
/*                              GLOBAL HELPERS                                */
/* -------------------------------------------------------------------------- */
.mat-primary-colour-100 {
  color: $finport-primary-colour-500;
}

.mat-primary-colour-75 {
  color: $finport-primary-colour-400;
}

.mat-primary-colour-50 {
  color: $finport-primary-colour-300;
}

.mat-primary-colour-25 {
  color: $finport-primary-colour-200;
}

.mat-accent-colour-900 {
  color: $finport-accent-colour-900;
}

.colour-white {
  color: $finport-colour-white;
}

.colour-black {
  color: $finport-colour-black;
}

.colour-negative-number {
  color: $finport-negative-colour;
}

.colour-positive-number {
  color: $finport-positive-colour;
}

.colour-grey {
  color: $finport-grey-font-colour;
}

.background-white {
  background: $finport-colour-white !important;
}

.finport-primary-bg-opacity-50 {
  background-color: rgba($finport-primary-colour-500, .5);
}

.background-gray {
  background: $finport-gray-footer;
}

.font-family-sans-serif {
  font-family: $finport-font-family-sans-serif !important;
}

.font-family-sans-serif-bold {
  font-family: $finport-font-family-sans-serif !important;
  font-weight: bold;
}

.font-family-serif-semi-bold {
  font-family: $finport-font-family-serif !important;
  font-weight: 600;
}

.font-family-serif {
  font-family: $finport-font-family-serif !important;
}

.font-size-larger {
  font-size: larger;
}

.font-size-smaller {
  font-size: smaller;
}

.font-family-inherit {
  font-family: inherit;
}

.pointer {
  cursor: pointer;
}

.overflow-x-auto {
  overflow-x: scroll;
  overflow-y: hidden;
}

.min-height-100 {
  min-height: 100%;
}

.mat-button-toggle-group {
  border: 2px $finport-primary-colour-500 solid !important;
  border-radius: 5px !important;


  .mat-pseudo-checkbox {
    display: none;
  }

  .mat-button-toggle {
    background-color: $finport-colour-white;
  }

  .mat-button-toggle-checked {
    background-color: $finport-accent-colour-300;
  }

  .mat-button-toggle-button {
    color: $finport-primary-colour-500;
  }
}

.mat-mdc-tab-label-container {
  border-bottom-style: none !important;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

.mat-mdc-slider {
  .mdc-slider__value-indicator {
    width: max-content;
    color: black !important;
    background-color: transparent !important;
    white-space: nowrap;
    font-size: medium !important;
  }
  .mdc-slider__value-indicator::before {
    display: none;
  }
  .mdc-slider__track--inactive{
    background-color: $finport-grey-font-colour !important;
  }
  .mdc-slider__value-indicator-text {
    color: black;
    display: flex;
    justify-content: center;
    font: medium 'Rokkitt Regular' !important;

  }
  .mdc-slider__value-indicator-container {
    bottom: 45px !important;
  }

}

.mat-ripple {
  display: none;
}

.strategy-selection-style-custom-slider .mat-mdc-slider {
  --mdc-slider-active-track-color: rgba(0, 0, 0, 0);


  .mdc-slider__track--active, .mdc-slider__track {
    height: 10px;
  }
  .mdc-slider__track--active {
    background: linear-gradient(to right, $finport-secondary-colour-100 0%, $finport-primary-colour-500 100%);
  }
  .mdc-slider__track--inactive{
    display: none;
  }

  .mdc-slider__tick-marks {
    .mdc-slider__tick-mark--active, .mdc-slider__tick-mark--inactive {
      height: 10px;
      width: 10px;
      left: -2px !important;
      background-color: lightgray;
      opacity: 100%;
    }
  }
  .mdc-slider__value-indicator-container {
    display: none;
  }

  .mdc-slider__thumb-knob {
    color: $finport-accent-colour-500 !important;
    border-color: $finport-accent-colour-500;
  }

  .mdc-slider__thumb--focused .mdc-slider__thumb-knob {
    border-color: $finport-accent-colour-500;
  }


}

.strategy-overview-mat-table {
  background-color: $finport-primary-colour-200;
  color: black;
  border-radius: 8px;
  overflow: hidden !important;

  .mdc-data-table__header-row {
    height: 30px;
    background-color: white;

    .mat-mdc-header-cell {
      font-weight: 500;
      border: none;
    }
  }

  .mat-mdc-table .mdc-data-table__row {
    height: 30px;
  }

  .mat-mdc-row {

    &:first-of-type > {
      .mat-mdc-cell:first-of-type {
        border-top-left-radius: 8px;
        overflow: hidden !important;
      }

      .mat-mdc-cell:last-of-type {
        border-top-right-radius: 8px;
        overflow: hidden !important;
      }
    }

  }

  .mat-mdc-cell,
  .mat-mdc-footer-cell {
    border: none;
    background-color: $finport-primary-colour-100;
  }
}


// DON'T ADD SPACES BETWEEN THE COLON AND THE VARIABLES
:root {
  --primary-colour-100:#{$finport-primary-colour-100};
  --primary-colour-200:#{$finport-primary-colour-200};
  --primary-colour-400:#{$finport-primary-colour-400};
  --primary-colour-500:#{$finport-primary-colour-500};
  --primary-colour-700:#{$finport-primary-colour-700};
  --primary-colour-900:#{$finport-primary-colour-900};

  --secondary-colour-100:#{$finport-secondary-colour-100};
  --secondary-colour-200:#{$finport-secondary-colour-200};
  --secondary-colour-300:#{$finport-secondary-colour-300};
  --secondary-colour-400:#{$finport-secondary-colour-400};
  --secondary-colour-500:#{$finport-secondary-colour-500};
  --secondary-colour-700:#{$finport-secondary-colour-700};
  --secondary-colour-900:#{$finport-secondary-colour-900};

  --accent-colour-300:#{$finport-accent-colour-300};
  --accent-colour-500:#{$finport-accent-colour-500};
  --accent-colour-900:#{$finport-accent-colour-900};

}

