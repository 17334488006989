@use '../node_modules/@angular/material/index' as mat;

/* -------------------------------------------------------------------------- */
/*                             MATERIAL THEME                                 */
/* -------------------------------------------------------------------------- */
$finport-colour-palette-primary: mat.m2-define-palette((
  50 : #e7eaf6,
  100 : #c4cbe9,
  200 : #CDD4ED,
  300 : #9BA8DB,
  400 : #6A7EC9,
  500 : #3953b7,
  600 : #334cb0,
  700 : #2c42a7,
  800 : #24399f,
  900 : #172990,
  A100 : #c6cdff,
  A200 : #93a1ff,
  A400 : #6074ff,
  A700 : #475eff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
));

$finport-colour-palette-secondary: mat.m2-define-palette((
  50 : #edf5fe,
  100 : #d1e6fd,
  200 : #b3d5fc,
  300 : #b0d2f8,
  400 : #9AC7FA,
  500 : #66aaf8,
  600 : #5ea3f7,
  700 : #5399f6,
  800 : #4990f5,
  900 : #387ff3,
  A100 : #ffffff,
  A200 : #ffffff,
  A400 : #d5e4ff,
  A700 : #bcd4ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )));

$finport-colour-palette-accent: mat.m2-define-palette((
  50 : #F8EDEC,
  100 : #EDD1CE,
  200 : #E1B3AE,
  300 : #EBCDC9,
  400 : #D79A94,
  500 : #C3675D,
  600 : #BD5F55,
  700 : #B5544B,
  800 : #AE4A41,
  900 : #A13930,
  A100 : #FFE9E7,
  A200 : #FFB9B4,
  A400 : #FF8A81,
  A500 : #fc7c73,
  A700 : #FF7268,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A500 : #000000,
    A700 : #000000,
  )
));

$finport-colour-palette-warn: mat.m2-define-palette(mat.$m2-red-palette);
$finport-theme: mat.m2-define-light-theme((
  color: (
    primary: $finport-colour-palette-primary,
    accent: $finport-colour-palette-accent,
    warn: $finport-colour-palette-warn,
  )
));


/* -------------------------------------------------------------------------- */
/*                            MATERIAL TYPOGRAPHY                             */
/* -------------------------------------------------------------------------- */
$finport-portal-typography: mat.m2-define-typography-config(
  $font-family: 'Lato Regular, sans-serif',
);


/* -------------------------------------------------------------------------- */
/*                                  FONTS                                     */
/* -------------------------------------------------------------------------- */
$finport-font-family-serif: 'Rokkitt Regular', serif;
$finport-font-family-sans-serif: 'Lato Regular', sans-serif;

/* -------------------------------------------------------------------------- */
/*                                 COLOURS                                    */
/* -------------------------------------------------------------------------- */
$finport-colour-white: #ffffff;
$finport-colour-black: rgba(0, 0, 0, .87);

$finport-primary-colour-900: mat.m2-get-color-from-palette($finport-colour-palette-primary,900);
$finport-primary-colour-700: mat.m2-get-color-from-palette($finport-colour-palette-primary,700);
$finport-primary-colour-500: mat.m2-get-color-from-palette($finport-colour-palette-primary,500);
$finport-primary-colour-400: mat.m2-get-color-from-palette($finport-colour-palette-primary,400);
$finport-primary-colour-300: mat.m2-get-color-from-palette($finport-colour-palette-primary,300);
$finport-primary-colour-200: mat.m2-get-color-from-palette($finport-colour-palette-primary,200);
$finport-primary-colour-100: mat.m2-get-color-from-palette($finport-colour-palette-primary,100);


$finport-secondary-colour-900: mat.m2-get-color-from-palette($finport-colour-palette-secondary,900);
$finport-secondary-colour-700: mat.m2-get-color-from-palette($finport-colour-palette-secondary,700);
$finport-secondary-colour-500: mat.m2-get-color-from-palette($finport-colour-palette-secondary,500);
$finport-secondary-colour-400: mat.m2-get-color-from-palette($finport-colour-palette-secondary,400);
$finport-secondary-colour-300: mat.m2-get-color-from-palette($finport-colour-palette-secondary,300);
$finport-secondary-colour-200: mat.m2-get-color-from-palette($finport-colour-palette-secondary,200);
$finport-secondary-colour-100: mat.m2-get-color-from-palette($finport-colour-palette-secondary,100);

$finport-accent-colour-900: mat.m2-get-color-from-palette($finport-colour-palette-accent, 900);
$finport-accent-colour-500: mat.m2-get-color-from-palette($finport-colour-palette-accent,500);
$finport-accent-colour-400: mat.m2-get-color-from-palette($finport-colour-palette-accent,400);
$finport-accent-colour-300: mat.m2-get-color-from-palette($finport-colour-palette-accent,300);

$finport-colour-light: #DCE5E1;
$finport-gray-footer: #f2f2f2;
$finport-dark-footer: #e7e5e5;
$finport-grey-font-colour: #6f6f6f;

$finport-dropdown-nav-colour: #edf2f0;
$finport-colour-light-nav-hover: #C3CECC;

$finport-negative-colour: #f44336;
$finport-positive-colour: #0f826e;
$finport-ahv-chart-colour: #0f816d;

$banner-title-colour: #000000bb;
